import axios from 'axios';

export default {
  getUsers(pageNumber, pageSize, sortField, sortDesc) {
    return axios.get('/api/UserManagement/GetUsersWithPagination', {
      params: {
        pageNumber, pageSize, sortField, sortDesc
      }
    });
  },
  GetUserManagement(id) {
    return axios.get('/api/UserManagement/GetUserManagement', {
      params: {
        id
      }
    });
  },
  updateUser(userUpdateModel) {
    return axios.post('/api/UserManagement/UpdateUser', userUpdateModel);
  },
};

